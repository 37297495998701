a {
  color: blue;
}

em {
  font-style: normal;
  font-weight: bold;
}

body {
  width: 100%;
  margin: 0;
  position: absolute;
}
